


/*===============================
    33.ERROR css 
================================*/

.error-area{
	overflow: hidden;
	& .error-item{
		& .title{
			font-size: 80px;
			line-height: 90px;
			padding-top: 45px;
			@media #{$md} {
				font-size: 60px;
				line-height: 70px;
			}
			@media #{$xs} {
				font-size: 25px;
				line-height: 35px;
			}
			@media #{$sm} {
				font-size: 46px;
				line-height: 56px;
			}
		}
		& span{
			font-size: 24px;
			color: #69a1bb;
			padding-top: 25px;
			@media #{$xs} {
				font-size: 18px;
			}
		}
		& .syotimer{
			& .syotimer__body{
				display: flex;
				justify-content: space-between;
				@media #{$xs} {
					display: block !important;

				}
				& .syotimer-cell{
					display: inline-block;
					height: 250px;
					width: 250px;
					border: 10px solid #f6fcff;
					border-radius: 50%;
					position: relative;
					z-index: 2;
					padding-top: 75px;
					@media #{$lg} {
						height: 200px;
						width: 200px;
						padding-top: 50px;
					}
					@media #{$md} {
						height: 150px;
						width: 150px;
						padding-top: 30px;
					}
					@media #{$xs} {
						height: 200px;
						width: 200px;
						margin: 20px 0;
						padding-top: 50px;
					}
					@media #{$sm} {
						height: 200px;
						width: 200px;
						margin: 20px;
						padding-top: 50px;
					}
					&::before{
						position: absolute;
						content: '';
						height: 108%;
						width: 108%;
						border-radius: 50%;
						border: 10px solid transparent;
						left: -10px;
						top: -10px;
						z-index: -1;
						border-left-color: $theme-color;
						border-right-color: $theme-color;
						transform: rotate(-45deg);
						@media #{$lg} {
							height: 110%;
							width: 110%;
						}
						@media #{$md} {
							height: 114%;
							width: 117%;
						}
						@media #{$sm} {
							height: 111%;
							width: 111%;
						}
					}
					& .syotimer-cell__value{
						font-size: 50px;
						font-weight: 700;
						font-family: $gilroy;
						color: $heading-color;
						line-height: 50px;
					}
					& .syotimer-cell__unit{
						font-weight: 700;
						color: #69a1bb;
					}
				}
			}
		}
	}
}


