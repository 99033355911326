/*-----------------------------------------------------------------------------------

    Template Name: Sample - Omnivus | Technology IT Solutions & Services HTML5 Template
    Template URI: http://ryetheme.com/demos/html/onmivus/
    Description: Sample - Technology IT Solutions & Services
    Author: MD THAHERIL ISLAM
    Author URI: https://ryetheme.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. banner
	05. services
	06. portfolio
	07. faq
	08. team
	09. action
	10. blog
	11. footer

-----------------------------------------------------------------------------------*/
.sticky{
	background-color: white !important;
	.navbar {
		background-color: white !important;
	}
}
@import 'variables';
@import 'mixin';
@import 'common';
@import 'header';
@import 'banner';
@import 'footer';
@import 'progress-bar';
@import 'features';
@import 'error';

@import "custom";


